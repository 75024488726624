import { FunctionComponent, useCallback, MouseEvent, useEffect } from 'react';
import { Stack, Divider, Drawer, DrawerProps, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { AppIconButton } from '../../components';
import { LinkToPage } from '../../utils/type';
import { useEventLogout, useEventSwitchDarkMode, useIsAuthenticated, useOnMobile } from '../../hooks';
import { SIDE_BAR_WIDTH, TOP_BAR_DESKTOP_HEIGHT } from '../config';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import HowToReg from '@mui/icons-material/HowToReg';
import PeopleOutline from '@mui/icons-material/PeopleOutline';
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ChangeHistory,
  Balance,
  Redeem,
  Settings,
  TransferWithinAStation,
  Circle,
  AcUnit,
  AdUnits,
  Airplay,
  Topic,
  ArchitectureSharp,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

interface Props extends Pick<DrawerProps, 'anchor' | 'className' | 'open' | 'variant' | 'onClose'> {
  items: Array<LinkToPage>;
}

/**
 * Renders SideBar with Menu and User details
 * Actually for Authenticated users only, rendered in "Private Layout"
 * @component SideBar
 * @param {string} anchor - 'left' or 'right'
 * @param {boolean} open - the Drawer is visible when true
 * @param {string} variant - variant of the Drawer, one of 'permanent', 'persistent', 'temporary'
 * @param {function} onClose - called when the Drawer is closing
 */
const SideBar: FunctionComponent<Props> = ({ user, anchor, open, variant, items, onClose, ...restOfProps }) => {
  const location = useLocation();
  const pathname = location.pathname;

  // Split the pathname by '/' and filter out empty strings
  const pathSegments = pathname.split('/').filter((segment) => segment !== '');

  // Get the last segment of the path (end value)
  const endValue = pathSegments[pathSegments.length - 1];

  // const isAuthenticated = state.isAuthenticated; // Variant 1
  const isAuthenticated = useIsAuthenticated(); // Variant 2
  const onMobile = useOnMobile();

  const onSwitchDarkMode = useEventSwitchDarkMode();
  const onLogout = useEventLogout();

  const handleAfterLinkClick = useCallback(
    (event: MouseEvent) => {
      if (variant === 'temporary' && typeof onClose === 'function') {
        onClose(event, 'backdropClick');
      }
    },
    [variant, onClose]
  );

  useEffect(() => {
    console.log(pathname, endValue, 'pathname');
  }, [pathname]);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      variant={variant}
      className="sidebarview"
      PaperProps={{
        sx: {
          backgroundColor: '#28344c !important',
          borderRight: '1px solid #26334D',
          width: SIDE_BAR_WIDTH,
          marginTop: onMobile ? 0 : variant === 'temporary' ? 0 : TOP_BAR_DESKTOP_HEIGHT,
          height: onMobile ? '100%' : variant === 'temporary' ? '100%' : `calc(100% - ${TOP_BAR_DESKTOP_HEIGHT})`,
        },
      }}
      onClose={onClose}
    >
      <Stack
        sx={{
          height: '100%',
          padding: 0,
        }}
        {...restOfProps}
        onClick={handleAfterLinkClick}
      >
        {isAuthenticated && (
          <>
            {/* <UserInfo showAvatar />
            <Divider /> */}
          </>
        )}

        {/* <SideBarNavList items={items} showIcons /> */}

        <MenuList className="menulist">
          <h5>GENERAL</h5>
          <MenuItem>
            <Link to={'/welcomeview'} className={pathname === '/' || pathname === '/welcomeview' ? 'active' : ''}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link to={'/welcomeview'} className={pathname === '/welcomeview' ? 'active' : ''}>
              <ListItemIcon>
                <Airplay fontSize="small" />
              </ListItemIcon>
              <ListItemText>New Board</ListItemText>
            </Link>
          </MenuItem> */}
          {user?.roles[0] === 'agentadmin' && (
            <MenuItem>
              <Link to={'/hierarchy'} className={pathname === '/hierarchy' ? 'active' : ''}>
                <ListItemIcon>
                  <ArchitectureSharp fontSize="small" />
                </ListItemIcon>
                <ListItemText>Hierarchy</ListItemText>
              </Link>
            </MenuItem>
          )}
          <Divider />
          {user?.roles[0] === 'agentadmin' && (
            <>
              <h5>OWNERS</h5>
              <MenuItem>
                <Link to={'/users/agentowner'} className={pathname === '/users/agentowner' ? 'active' : ''}>
                  <ListItemIcon>
                    <PeopleOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Active Owners</ListItemText>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/approvalusers/agentowner'}
                  className={pathname === '/approvalusers/agentowner' ? 'active' : ''}
                >
                  <ListItemIcon>
                    <HowToReg fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>For Approvals</ListItemText>
                </Link>
              </MenuItem>
              <Divider />
            </>
          )}
          {(user?.roles[0] === 'agentadmin' || user?.roles[0] === 'agentowner') && (
            <>
              <h5>MASTER AGENTS</h5>
              <MenuItem>
                <Link to={'/users/masteragent'} className={pathname === '/users/masteragent' ? 'active' : ''}>
                  <ListItemIcon>
                    <PeopleOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Active Agents</ListItemText>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/approvalusers/masteragent'}
                  className={pathname === '/approvalusers/masteragent' ? 'active' : ''}
                >
                  <ListItemIcon>
                    <HowToReg fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>For Approvals</ListItemText>
                </Link>
              </MenuItem>
              <Divider />
            </>
          )}
          {(user?.roles[0] === 'agentadmin' || user?.roles[0] === 'masteragent') && (
            <>
              <h5>GOLDEN AGENTS</h5>
              <MenuItem>
                <Link to={'/users/goldenagent'} className={pathname === '/users/goldenagent' ? 'active' : ''}>
                  <ListItemIcon>
                    <PeopleOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Active Agents</ListItemText>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/approvalusers/goldenagent'}
                  className={pathname === '/approvalusers/goldenagent' ? 'active' : ''}
                >
                  <ListItemIcon>
                    <HowToReg fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>For Approvals</ListItemText>
                </Link>
              </MenuItem>
              <Divider />
            </>
          )}
          {(user?.roles[0] === 'agentadmin' || user?.roles[0] === 'goldenagent') && (
            <>
              <h5>PLAYERS</h5>
              <MenuItem>
                <Link to={'/users/agentplayer'} className={pathname === '/users/agentplayer' ? 'active' : ''}>
                  <ListItemIcon>
                    <SupervisedUserCircle fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Active Players</ListItemText>
                </Link>
              </MenuItem>
              <Divider />
            </>
          )}

          <h5>PLAYER CASHIN/CASHOUT</h5>
          <MenuItem>
            <Link to={'/bets'} className={pathname === '/bets' ? 'active' : ''}>
              <ListItemIcon>
                <Redeem fontSize="small" />
              </ListItemIcon>
              <ListItemText>Bets</ListItemText>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={'/settlements'} className={pathname === '/settlements' ? 'active' : ''}>
              <ListItemIcon>
                <Balance fontSize="small" />
              </ListItemIcon>
              <ListItemText>Commissions Report</ListItemText>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link to={'/commissionbyperiod'} className={pathname === '/commissionbyperiod' ? 'active' : ''}>
              <ListItemIcon>
                <Circle fontSize="small" />
              </ListItemIcon>
              <ListItemText>Commissions By period</ListItemText>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link to={'/commissionsettlement'} className={pathname === '/commissionsettlement' ? 'active' : ''}>
              <ListItemIcon>
                <AcUnit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Commission Settlement</ListItemText>
            </Link>
          </MenuItem>

          {user?.roles[0] !== 'goldenagent' && (
            <MenuItem>
              <Link to={'/approvewithdraws'} className={pathname === '/approvewithdraws' ? 'active' : ''}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {user?.roles[0] === 'agentadmin' && 'Approve Owner Withdrawals'}
                  {user?.roles[0] === 'agentowner' && 'Approve Master Withdrawals'}
                  {user?.roles[0] === 'masteragent' && 'Approve Golden Withdrawals'}
                </ListItemText>
              </Link>
            </MenuItem>
          )}
          {user?.roles[0] !== 'agentadmin' && (
            <MenuItem>
              <Link to={'/requestwithdraws'} className={pathname === '/requestwithdraws' ? 'active' : ''}>
                <ListItemIcon>
                  <TransferWithinAStation fontSize="small" />
                </ListItemIcon>
                <ListItemText>My Withdrawals</ListItemText>
              </Link>
            </MenuItem>
          )}
          {user?.roles[0] === 'agentadmin' && (
            <MenuItem>
              <Link to={'/agentsettlement'} className={pathname === '/agentsettlement' ? 'active' : ''}>
                <ListItemIcon>
                  <AdUnits fontSize="small" />
                </ListItemIcon>
                <ListItemText>Agent Settlement</ListItemText>
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link to={'/historicalreports'} className={pathname === '/historicalreports' ? 'active' : ''}>
              <ListItemIcon>
                <ChangeHistory fontSize="small" />
              </ListItemIcon>
              <ListItemText>Historical Reports</ListItemText>
            </Link>
          </MenuItem>
          {user?.roles[0] === 'agentadmin' && (
            <MenuItem>
              <Link to={'/usershierarchyexcel'} className={pathname === '/usershierarchyexcel' ? 'active' : ''}>
                <ListItemIcon>
                  <Topic fontSize="small" />
                </ListItemIcon>
                <ListItemText>Users Hierarchy Excel</ListItemText>
              </Link>
            </MenuItem>
          )}
        </MenuList>

        <Divider />

        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          {isAuthenticated && <AppIconButton icon="logout" title="Logout Current User" onClick={onLogout} />}
        </Stack>
      </Stack>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SideBar);
