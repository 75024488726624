import { AppBar, IconButton, Link, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import Logout from '@mui/icons-material/Logout';
import { AccountCircle, ChangeCircle } from '@mui/icons-material';
import { useOnMobile } from '../../hooks/layout';
import React from 'react';

interface Props {
  endNode?: ReactNode;
  startNode?: ReactNode;
  title?: string;
}

/**
 * Renders TopBar composition
 * @component TopBar
 */
const TopBar: FunctionComponent<Props> = ({ user, endNode, startNode, title = '', ...restOfProps }) => {
  const onMobile = useOnMobile();
  const sidebarVariant = onMobile ? 'temporary' : 'persistent';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = () => {};
  const handleClose = () => {};
  return (
    <AppBar component="div" sx={{ background: '#202B40 !important' }} {...restOfProps}>
      <Toolbar disableGutters sx={{ paddingX: 1 }}>
        {user?.roles[0] && startNode}
        <Link
          href="/auth/login"
          sx={{
            display: 'flex',
            marginLeft: '8px',
          }}
        >
          {sidebarVariant === 'persistent' ? (
            <img height={'30px'} src={`../../img/logo.svg`} />
          ) : (
            <img height={'30px'} src={`../../img/mobile_logo.svg`} />
          )}
        </Link>
        <Typography
          variant="h6"
          sx={{
            marginX: 1,
            flexGrow: 1,
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {/* {title} */}
        </Typography>
        {user?.roles[0] && (
          <Link
            href="/changepassword"
            sx={{
              display: 'flex',
              color: '#F3722C',
              fontSize: '12px',
              marginLeft: '15px;',
              marginRight: '10px',
              fontWeight: '800',
            }}
          >
            <ListItemIcon sx={{ minWidth: '24px', color: '#F3722C' }}>
              <ChangeCircle fontSize="small" />
            </ListItemIcon>
            Change Password
          </Link>
        )}
        <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: '800' }}>
          {user?.firstName.toUpperCase()} {user?.lastName.toUpperCase()}
        </Typography>

        {user?.roles[0] && (
          <>
            {/* <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div> */}
            <Link
              href="/logout"
              sx={{
                display: 'flex',
                color: '#F3722C',
                fontSize: '12px',
                marginLeft: '15px;',
                marginRight: '10px',
                fontWeight: '800',
              }}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: '#F3722C' }}>
                <Logout fontSize="small" />
              </ListItemIcon>
              LOGOUT
            </Link>
          </>
        )}
        {/* {endNode} */}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
});

//export default TopBar;
export default connect(mapStateToProps, null)(TopBar);
